import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '99, 99, 99',
		'primary-dark': '56, 56, 56',
		'accent': '255, 120, 0',
		'accent-plus': '0, 0, 0',
	},
});
